<template>
  <div>
    <b-form-checkbox
      @click.native="popupActive = true"
      value="1"
      unchecked-value="2"
      v-model="form.category_active"
      switch
      class="table-status-icon"
    ></b-form-checkbox>

    <div class="demo-alignment">
      <vs-popup
        :id="'popmodalS' + params.data.category_id"
        class="holamundo p-2"
        :button-close-hidden="true"
        title="Subcategory Status"
        :active.sync="popupActive"
      >
        <div class="text-center">
          <h5>Do you want to change Status ?</h5>
          <!-- SUBMIT BUTTON -->
          <div class="vx-row mt-5">
            <div class="vx-col mx-auto">
              <!-- :disabled="submitStatus" -->
              <vs-button
                color="success"
                type="filled"
                @click="onChangeCheckbox($event)"
                class="mr-3"
                >Yes</vs-button
              >
              <vs-button color="danger" type="filled" @click="privStatus()"
                >No</vs-button
              >
            </div>
          </div>
        </div>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import categoryService from "@/services/categoryService.js";

export default Vue.extend({
  data() {
    return {
      params: null,
      popupActive: false,
      form: {
        category_active: "1",
      },
      copy_category_active: null,
    };
  },
  beforeMount() {
    this.form = this.params.data;
    this.copy_category_active = this.form.category_active;
  },
  beforeDestroy() {
    let _id = document.getElementById(
      "popmodalS" + this.params.data.category_id
    );
    _id.setAttribute("style", "display: none;");
  },
  methods: {
    onChangeCheckbox: function(e, value) {
      this.$vs.loading();
      // this.value = this.value === "1" ? "2" : "1";
      let id = this.params.data.category_id;
      let payload = {
        // employment_name: this.params.data.employment_name,
        // company_id: this.params.data.employment_id,
        // description: this.params.data.description,
        category_active: this.form.category_active,
      };
      categoryService
        .editCategory(payload, id)
        .then((response) => {
          this.$vs.loading.close();
          const { data } = response;
          if (data.Status) {
            this.$vs.notify({
              title: "Updated!",
              text: data.data.message,
              iconPack: "feather",
              icon: "check_box",
              color: "success",
            });
            this.popupActive = false;
          } else {
            this.$vs.loading.close();
            console.log("data :", data);
            this.$vs.notify({
              title: "Something went wrong",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((err) => {
          this.$vs.loading.close();
        });
    },
    privStatus: function() {
      this.popupActive = false;
      this.form.category_active = this.copy_category_active;
    },
  },
});
</script>
