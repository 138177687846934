<template>
  <div id="dashboard-analytics">
    <div class="vx-row">
      <div class="vx-col w-full">
        <router-link to="/master/category">
          <feather-icon icon="ArrowLeftCircleIcon" svgClasses="h-8 w-8" />
        </router-link>
      </div>

      <!-- CATEGORY FORMS -->

      <!-- CATEGORY LIST -->
      <vs-tabs v-if="checkPermission(['sub_category', 'GET'])">
        <vs-tab label="Subcategory List" icon-pack="feather" icon="icon-box">
          <div class="tab-text">
            <!-- CATEGORY LIST -->
            <div>
              <SubCategoryList listType="noarchive" />
            </div>
          </div>
        </vs-tab>
        <vs-tab label="Archive List" icon-pack="feather" icon="icon-box">
          <!-- CATEGORY ARCHIVE LIST -->
          <div>
            <SubCategoryList listType="archive" />
          </div>
        </vs-tab>
      </vs-tabs>
    </div>
  </div>
</template>

<script>
import SubCategoryList from "@/views/apps/masters/category/subcategory/SubCategoryList.vue";

export default {
  data() {
    return {
      isActive: false,
      analyticsData: "",
      isImp: false,
      navbarSearchAndPinList: this.$store.state.navbarSearchAndPinList,
      show: false,
      items: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      nextNum: 10,
      tableList: [
        "vs-th: Component",
        "vs-tr: Component",
        "vs-td: Component",
        "thread: Slot",
        "tbody: Slot",
        "header: Slot",
      ],
      categoryID: "",
    };
  },
  beforeMount: function() {
    this.categoryID = this.$route.params.categoryID;
    this.getSubCategories();
  },
  methods: {
    myFilter: function() {
      this.isActive = !this.isActive;
    },
    getSubCategories: function() {
      // let payload = {
      //   categoryID: this.categoryID
      // };
      // categoryService
      //   .getSubCategoryByCategory(payload)
      //   .then(response => {
      //     const { data } = response;
      //     if (!data.error) {
      //       this.subCategoryList = data.data;
      //     } else {
      //     }
      //   })
      //   .catch(error => {
      //     console.log("error :", error);
      //   });
    },
  },
  components: {
    // subCategoryForm,
    SubCategoryList,
  },
};
</script>

<style lang="scss">
#dashboard-analytics {
  .greet-user {
    position: relative;
    .decore-left {
      position: absolute;
      left: 0;
      top: 0;
    }
    .decore-right {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  @media (max-width: 576px) {
    .decore-left,
    .decore-right {
      width: 140px;
    }
  }
}
</style>
